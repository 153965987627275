export default [
  {
    key: 'outletCode',
    label: 'field.outletCode',
    type: 'text',
    operator: '%',
  },
  {
    key: 'ownerName',
    label: 'field.ownerName',
    type: 'text',
    operator: '%',
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    type: 'text',
    operator: '%',
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    type: 'text',
    operator: '%',
  },
  {
    key: 'contactNumber',
    label: 'field.contactNumber',
    type: 'tel',
    operator: '%',
  },
]
